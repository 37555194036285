import React from "react";

import _move from "../lib/move";
import componentMount from "../lib/componentMount"
import PromoStickyBar from "@pcloud/web-utilities/dist/resources/components/PromoSticky/PromoStickyBar"

const _attachList = [
  {
    attach: self,
    list: [
      //components
      "PromoStickyBar"
    ]
  }
]

const prepComponents = {
  PromoStickyBar: componentMount(props => <PromoStickyBar {...props} />)
};

_move(prepComponents, _attachList);